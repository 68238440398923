import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import SEO from "@/components/seo"

import ContentList from '../components/content/content-list'
import HeroSmall from '../components/sections/hero-small'

const ContentPage = ({ data, pageContext }) => {
  return (
    <>
      {/*<SEO page={data.strapi.content.meta} site={data.strapi.meta.data} fallback={data.site.siteMetadata} />*/}
      <Layout>
      <HeroSmall data={{ header: pageContext.type, label: null, copy: null, image: null, cta: null}}/>
      <ContentList content={data.strapi.contentType.contents}/>
      
      </Layout>
    </>
  )
}

export default ContentPage

export const query = graphql`
query ContentQuery($id: ID!) {
  strapi {
    contentType(id: $id) {
      id
      slug
      type
      contents {
        author
        date
        id
        slug
        title
        content_type {
          type
          slug
        }
      }
    }
  }
}
`